<template>
  <ErrorMessage
    :condition="value && value.length > maxlength"
    :message="message"
  ></ErrorMessage>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    maxlength: {
      type: Number,
    },
  },
  computed: {
    message() {
      if (!this.value) {
        return "";
      }
      return `Maksymalna ilość znaków to ${this.maxlength}, aktualnie masz
      ${this.value.length}.`;
    },
  },
  components: {
    ErrorMessage: () => import("@/components/controls/ErrorMessage"),
  },
};
</script>

<style scoped>
.error-message {
  font-size: 15px;
  color: var(--v-error-base);
}
</style>